// import React from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)

    .init({
        debug: true,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false, // not needed for react as it escapes   by default
        },
        resources: {
            en: {
                translation: {

                    // Header Start 
                    home: 'Home',
                    contactUs: 'Contact Us',
                    aboutUs: 'About Us',
                    services: 'Services',
                    OurTeam: "Our Team",

                    // Header End

                    // ============================ // 


                    // Home Start
                    teslapowerwallbatterybackuppoweroutage: "Tesla Powerwall Battery Backup Power Outage",
                    TheRevolutionaryIQ8Series: "The Revolutionary IQ8 Series",
                    TheRevolutionaryIQ8SeriesDetail: "ON or Off the grid, Our smartest micro inverter ever",
                    Maunfacturing: "U.S Maunfacturing",
                    Maunfacturingdetail: "Our most powerful battery yet",
                    readMore: 'Read More',
                    weCreate: 'We Create',
                    solar: 'Solar',
                    Warranty: "Warranty",
                    solutions: 'Solutions',
                    getStartedNow: "Get Started Now",
                    learnMore: "Learn More",
                    singlPlySolar: 'SINGLE PLY SOLAR',
                    panelSetup: 'Modified Solar',
                    panelSetupParagraph: 'Redefining Solar Energy for Your Unique Needs',
                    buildUpSolar: 'High Security',
                    buildUpSolarParagraph: 'Protecting Your Solar Investment with Robust Solutions',
                    loremIpsum: 'Lorem ipsum sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore.',
                    solarHelpUs: "Mgp HELP BUSINESSES ELEVATE THEIR SOLAR.",
                    aboutUsPara: "With more than four decades of proven success in quality solar services, Shelder solar and Retro-Fit group is a family-owned & operated business serving the greater San Fransisco area state.Our expert team has been dedicated to providing the best service and quality available.",

                    ourServices: 'Our services',
                    weProvide: 'We Provide Superior solar Services with good passions',

                    interiorEnergy: 'Interior Energy',
                    modifiedSolar: 'Modified solar',
                    singlePlySolar: 'Interior Energy',
                    singlePlySolarParagraph: 'Transforming Your Home with Solar Power ',
                    highSecurity: 'High Security',
                    teamSupport: 'Team Support',
                    needConsultation: 'NEED CONSULTATION',
                    weAreHere: "WE ARE HERE FOR YOUR ESTIMATE PRICE.",
                    team: 'Team',
                    ourTeamMember: 'Our team member',

                    getInTouch: 'Get in touch',
                    getCatchUs: 'Get Catch us from here. right now',
                    getDirection: 'get direction',

                    officeAddress: 'Office address',
                    phoneNumber: 'Phone number',
                    emailAddress: 'email address',
                    getAQuote: "get a quote",

                    weHelp: "We help businesses elevate their solar.",
                    process: 'Process',
                    ourStandard: 'OUR STANDARD WORKING PROCESS.',
                    location : "LOCATION",


                    LiveOnSunshine: "Live On Sunshine",
                    EnphaseintheRealWorld: 'MGP Energy in the Real World',
                    Energy101: "Energy 101",
                    EnphaseInstallerCenter: 'MGP Energy Installer Center',
                    ControlHomeConnectappliancesfromtheEnphaseApp: "Control Your Home with App",
                    Ourcustomersstories: " Our customers’ stories.",
                    Yourfrequentlyaskedquestionsanswered: "Your frequently asked questions, answered.",
                    Alltheinformationyouneedinoneplace: "All the information you need in one place.",
                    support: "24/7 Support",
                    RequestforQoute: "Request for Qoute",
                    GetStartedNow: "Get Started Now",
                    BackupEnergy: "Backup Energy",
                    Storage: "Storage",
                    Outage: "Outage",
                    Protection: "Protection",
                    Energy: "Energy",
                    Independence: "Independence",
                    YourSolarproductcustomized: "Your Solar product, customized",
                    PowerwallSpecs: "Powerwall Specs",
                    EnergyCapacity: "Energy Capacity",
                    OnGridPower: "On-Grid Power",
                    continuous: "continuous",
                    BackupPower: "Backup Power",
                    peak: "peak",
                    SizeandWeight: "Size and Weight",
                    Inverter: "Inverter",




                    // Home End


                    // Footer Start

                    company: "company",
                    community: 'COMMUNITY',
                    address: 'Address',


                    blogs: "Blogs",
                    careers: "Careers",
                    partners: "Partners",
                    managedServices: "Managed Services",
                    businessSolutions: 'Business Solutions',
                    managePartner: 'Managing Partner along with Senior Counsels',

                    yourName: 'Your Name',
                    yourEmail: 'Your Email',
                    yourPhone: 'Your Phone Number',
                    yourSubject: 'Your Subject',
                    yourMessage: 'Your Message',

                    consultation: 'Consultation',
                    proposal: 'Proposal',
                    installation: 'Installation',
                    inspection: 'Inspection',
                    Certifications: "Certifications",

                    yourPartnerInSolar: "Your Partner for solar solutions",




                    // Footer End
                    //Service Start

                    roofConsultancy: "ROOF-CONSULTANCY",
                    DidecatedField: "Dedicated solar Solutions with 25 Years Experience in this field.",
                    Category: "CATEGORY",
                    RoofConsultancy: "Roof Consultancy",
                    TimelyServices: "Timely Services",
                    AdvisoryServices: "Advisory Services",
                    solarStructuring: "solar Structuring",
                    ExperienceDesign: "Experience Design",
                    CreativeEngineering: "CreativeEngineering",
                    features: "features",
                    WhyChooseUs: 'Why Choose Us',
                    Brouchers: 'Brouchers',
                    DownloadPdfFile: "DOWNLOAD PDF FILE",
                    OurIsoCertificates: "OUR ISO CERTIFICATES",
                    geteveryanswershere: "get every answers here",
                    faq: "faq",
                    quickLinks: 'Quick Links',


                    shieldSolar: 'Shelder solar & Retro-Fit group is a family-owned & operated business serving the greater San Fransisco. In 1991 we expanded our outstanding service lists.',
                    mostSuccessful: 'The most successful business projects are always those that are driven by an employee who has the authority, vision and influence to drive the required changes in a business.',

                    advisorySkills: 'Advisory skills',
                    guaranteedSkills: ' Guaranteed skills',
                    interiorSkills: ' Interior skills',
                    businessAndManagementExperts: ' Business and management experts',
                    weWorkedWith: 'our happy clients',


                    //Service End

                    //ReadMore Start

                    ReadSinglePlySolar: 'Single Ply Solar have long been a reliable source of renewable energy, providing clean and sustainable electricity for hundreds of years. These special power plants take advantage of the natural forces created by water flow to generate electricity from large-scale hydroelectric dams and small-scale microhydro systems. With advances in technology, hydropower is becoming even more efficient and is increasingly being used to reduce reliance on traditional forms of energy. Whether you’re looking for ways to manage your household budget or increase your environmental footprint, understanding the benefits and drawbacks of harnessing this renewable energy source can help you make an informed decision about whether hydropower is right for you.',
                    ReadPanelSetup: "Solar panel technology is advancing rapidly with greater efficiency and lower prices resulting in a huge increase in demand. However, despite the massive advancements in technology, basic solar panel construction hasn’t changed much over the years. Most solar panels are still made up of a series of silicon crystalline cells sandwiched between a front glass plate and a rear polymer plastic back-sheet supported within an aluminium frame.Once installed, solar panels are subjected to severe conditions over the course of their 25+ year life. Extreme variations in temperature, humidity, wind and UV radiation can put enormous stress on a solar panel. Fortunately, most panels are well-engineered to withstand extreme weather. However, some panels can still fail in several ways including water ingress, cell micro-fractures and potential induced degradation or PID. This is why it is vital solar panels are manufactured using only the highest quality components. In our other article, best solar panels, we highlight the leading manufacturers using the highest quality materials together with testing to the highest industry standard",
                    ReadbuildUpSolar: "BUILT-UP SOLAR  Solar asset underperformance continues to worsen, with projects “chronically underperforming” P99 estimates and modules degrading faster than previously anticipated, risk management firm kWh Analytics has found. kWh Analytics’ new Solar Risk Assessment, released this week, pulls together a raft of industry experts to assess the greatest risks to the global solar industry and has identified a number of serious threats which threaten to reduce investor returns and damage the industry’s credibility moving forward.The report itself is separated into three sections, detailing the risk to solar assets posed by financial modeling, operational performance and extreme weather. Each section features insight from a range of contributors including the likes of PV Evolution Labs, BloombergNEF, Fracsun and Nextracker.",
                    singleply: "Single Ply Solar",
                    panel: "Panel Setup",
                    //ReadMore End

                    powerwall: "powerwall",
                    wallConnector: "Wall Connector",
                    theMostConvenlent: "The most convenlent way to charge at home",
                    design: "Design",
                    modernAndCompact: "Modern And Compact",
                    withEasyand: "With easy installation and a minimalist design, Powerwall complements a variety of home styles and solar systems. The compact, all-in-one construction features versatile mounting options for indoor or outdoor spaces. Chat with an energy advisor to ask any questions about Tesla Powerwall.",
                    orderPowerwall: "order Powerwall",
                    builtupsolar: "Built Up Solar",
                    ModifiedSolarDetail: "Redefining Solar Energy for Your Unique Needs ",
                    HighSecurityDetail: " Protecting Your Solar Investment with Robust Solutions",
                    SinglePlySolarDetail: "	Redefining Efficiency and Sustainability in Roofing",
                    PanelSetupDetail: "Optimizing Solar Power Generation for Your Space",
                    BuiltUpSolarDetail: " Harnessing Solar Power with Seamless Integration",
                    TeamsupportDetail: "Empowering Your Solar Team for Success",

                    // powerwall Start
                    GetPersonalized: "Get your Personalized Home Solar Panel System Design, Today!",
                    GetPersonalizedDescription: "At MGP Energy, we're dedicated to empowering homeowners with clean, renewable energy. With our personalized solar panel system design service, we cater to your unique energy needs and budget. Solar energy helps you save on electricity bills and reduces your carbon footprint. Our experienced team ensures a seamless installation process, maximizing system efficiency. Take the first step towards a greener future. Contact MGP Energy today to start your solar journey.",
                    // powerwall End

                    ProjectDone: "Project Done",
                    ActiveClient: "ActiveClient",
                    DrinkCoffee: "Drink Coffee",
                    AwardWinning: 'Award Winning',

                },
            },
            de: {
                translation: {
                    modernAndCompact: "Moderna y compacta",
                    withEasyand: "Con una instalación sencilla y un diseño minimalista, Powerwall complementa una variedad de estilos de hogar y sistemas solares. La construcción compacta todo en uno presenta opciones de montaje versátiles para espacios interiores o exteriores. Chatea con un asesor de energía para hacer cualquier pregunta sobre Tesla Powerwall.",
                    orderPowerwall: "ordenar Powerwall",



                    // Header Start 
                    design: "Diseño",
                    home: 'hogar',
                    contactUs: 'Contacta',
                    aboutUs: 'Sobre nosotras',
                    services: 'servicios',
                    OurTeam: "Nuestro equipo",
                    singlePlySolar: ' Interior Energy',
                    singlePlySolarParagraph: 'Potenciando tu Espacio con Soluciones Energéticas Eficientes ',
                    panelSetup: ' Modified Solar',
                    panelSetupParagraph: 'Redefiniendo la Energía Solar para Cubrir tus Necesidades Únicas ',
                    buildUpSolar: ' Alta Seguridad Solar',
                    buildUpSolarParagraph: 'Protegiendo tu Inversión Solar con Soluciones Robustas ',
                    loremIpsum: 'spanish sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore.',

                    // Header End 

                    // ======================================== //

                    // Home Start
                    teslapowerwallbatterybackuppoweroutage: "Corte de energía de respaldo de batería Tesla Powerwall",
                    TheRevolutionaryIQ8Series: "La revolucionaria serie IQ8",
                    TheRevolutionaryIQ8SeriesDetail: "ENCENDIDO o fuera de la red, nuestro microinversor más inteligente de todos los tiempos",
                    Maunfacturing: "Fabricación",
                    Maunfacturingdetail: "Nuestra batería más poderosa hasta el momento",
                    singlPlySolar: "solar de una sola capa",
                    weCreate: 'Nosotras creamos ',
                    solar: 'Solar',
                    solutions: 'soluciones',
                    getStartedNow: "Empieza ahora",
                    learnMore: "aprende más",
                    solarHelpUs: "Mgp AYUDA A LAS EMPRESAS A ELEVAR SU ENERGÍA SOLAR.",
                    readMore: 'leer más',
                    aboutUsPara: "Con más de cuatro décadas de éxito comprobado en servicios solares de calidad, Shelder solar and Retro-Fit group es una empresa familiar y operada que presta servicios en el estado del área metropolitana de San Francisco. Nuestro equipo de expertos se ha dedicado a brindar el mejor servicio y la mejor calidad disponible.",
                    ourServices: 'Nuestros servicios',
                    weProvide: 'Brindamos servicios solares superiores con buenas pasiones ',


                    interiorEnergy: 'Energía interior',
                    modifiedSolar: 'Solar modificada',
                    Warranty: "Garantía",
                    highSecurity: 'Alta seguridad',
                    teamSupport: 'apoyo de equipo',
                    needConsultation: 'NECESITO CONSULTA',

                    weAreHere: "ESTAMOS AQUÍ PARA SU PRECIO ESTIMADO.",
                    team: 'equipo',
                    ourTeamMember: 'nuestro miembro del equipo',
                    getInTouch: 'Ponerse en contacto',
                    getCatchUs: 'Consigue Atrápanos desde aquí. ahora mismo',
                    Certifications: "Certificaciones",
                    getDirection: 'obtener dirección',

                    officeAddress: 'Dirección de la oficina',
                    phoneNumber: 'Número de teléfono',
                    emailAddress: 'dirección de correo electrónico',
                    getAQuote: "Consigue una cotización",
                    company: "compañía",
                    community: 'COMUNIDAD',
                    address: 'DIRECCIÓN',
                    quickLinks: 'Enlaces Rápidos',

                    blogs: "Blogs",
                    careers: "carreras",
                    partners: "socias",
                    managedServices: "Servicios gestionados",
                    businessSolutions: 'Soluciones de negocios',
                    managePartner: 'Socio gerente junto con Senior Counsels',




                    yourName: 'Su nombre',
                    yourEmail: 'Tu correo electrónico',
                    yourPhone: 'Su número de teléfono',
                    yourSubject: 'Tu asunto',
                    yourMessage: 'Tu mensaje',

                    location : "UBICACIÓN",


                    weHelp: "Ayudamos a las empresas a elevar su energía solar.",


                    ourStandard: 'NUESTRO PROCESO DE TRABAJO ESTÁNDAR.',
                    process: 'Proceso',



                    consultation: 'consulta',
                    proposal: 'propuesta',
                    installation: 'instalación',
                    inspection: 'inspección',
                    powerwall: "poder de pared",
                    wallConnector: "Conector de pared",

                    LiveOnSunshine: "Vive en el sol",
                    EnphaseintheRealWorld: 'MGP Energy en el mundo real',
                    Energy101: "Energía 101",
                    EnphaseInstallerCenter: "CENTRO INSTALADOR MGP ENERGY",
                    ControlHomeConnectappliancesfromtheEnphaseApp: "Controla tu hogar con la aplicación",
                    Ourcustomersstories: "Historias de nuestros clientes.",
                    Yourfrequentlyaskedquestionsanswered: "Tus preguntas más frecuentes, respondidas.",
                    Alltheinformationyouneedinoneplace: "Toda la información que necesitas en un solo lugar.",
                    support: "24/7 Apoyo",
                    RequestforQoute: "Solicitud de Cotización",
                    GetStartedNow: "Empieza ahora",
                    BackupEnergy: "Energía de respaldo",
                    Storage: "Almacenamiento",
                    Outage: "Corte",
                    Protection: "Proteccion",
                    Energy: 'Energía',
                    Independence: "Independencia",
                    YourSolarproductcustomized: "Tu producto Solar, a tu medida",
                    PowerwallSpecs: "Especificaciones de Powerwall",
                    EnergyCapacity: "Capacidad de energía",
                    OnGridPower: "Energía en la red",
                    continuous: "continua",
                    BackupPower: "Energía de respaldo",
                    peak: "cima",
                    SizeandWeight: "Tamaño y peso",
                    Inverter: "Inversora",




                    // Home End

                    //Service Start

                    roofConsultancy: "CONSULTORÍA DE TECHO",
                    DidecatedField: "SOLUCIONES SOLARES DEDICADAS CON 25 AÑOS DE EXPERIENCIA EN ESTE CAMPO.",
                    Category: "CATEGORÍA",
                    RoofConsultancy: "Consultoría de techos",
                    TimelyServices: "Servicios oportunos",
                    AdvisoryServices: "Servicios de asesoramiento",
                    solarStructuring: "Estructuración solar",
                    ExperienceDesign: "Diseño de experiencia",
                    CreativeEngineering: "Ingeniería creativa",
                    features: "características",
                    WhyChooseUs: 'Por qué elegirnos',
                    Brouchers: "Folletos",
                    DownloadPdfFile: "Descargar archivo PDF",
                    OurIsoCertificates: "NuestrosCertificadosIso",
                    geteveryanswershere: "obtener todas las respuestas aquí",
                    faq: "Preguntas más frecuentes",

                    yourPartnerInSolar: "Su socio para soluciones solares",
                    shieldSolar: 'El grupo Shelder solar & Retro-Fit es una empresa familiar y operada que presta servicios en el área metropolitana de San Francisco. En 1991 ampliamos nuestras listas de servicios destacados.',


                    mostSuccessful: 'Los proyectos comerciales más exitosos son siempre aquellos que están impulsados ​​por un empleado que tiene la autoridad, la visión y la influencia para impulsar los cambios necesarios en un negocio.',
                    advisorySkills: 'Habilidades de asesoramiento',
                    guaranteedSkills: 'Habilidades garantizadas',
                    interiorSkills: ' Habilidades interiores',
                    businessAndManagementExperts: 'Expertos en administración y negocios',
                    weWorkedWith: 'nuestros clientes felices',
                    theMostConvenlent: "La forma más conveniente de cargar en casa",


                    //Service End

                    //ReadMore Start

                    ReadSinglePlySolar: 'Single Ply Solar ha sido durante mucho tiempo una fuente confiable de energía renovable, proporcionando electricidad limpia y sostenible durante cientos de años. Estas centrales eléctricas especiales aprovechan las fuerzas naturales creadas por el flujo de agua para generar electricidad a partir de represas hidroeléctricas a gran escala y microsistemas hidroeléctricos a pequeña escala. Con los avances tecnológicos, la energía hidroeléctrica se está volviendo aún más eficiente y se utiliza cada vez más para reducir la dependencia de las formas tradicionales de energía. Ya sea que esté buscando formas de administrar el presupuesto de su hogar o aumentar su huella ambiental, comprender los beneficios y los inconvenientes de aprovechar esta fuente de energía renovable puede ayudarlo a tomar una decisión informada sobre si la energía hidroeléctrica es adecuada para usted.',
                    ReadPanelSetup: "La tecnología de paneles solares avanza rápidamente con una mayor eficiencia y precios más bajos, lo que resulta en un gran aumento de la demanda. Sin embargo, a pesar de los enormes avances tecnológicos, la construcción básica de paneles solares no ha cambiado mucho a lo largo de los años. La mayoría de los paneles solares todavía se componen de una serie de células cristalinas de silicio intercaladas entre una placa de vidrio frontal y una lámina posterior de plástico polimérico soportada dentro de un marco de aluminio. Una vez instalados, los paneles solares están sujetos a condiciones severas durante el transcurso de sus 25 + años de vida. Las variaciones extremas de temperatura, humedad, viento y radiación ultravioleta pueden ejercer una enorme presión sobre un panel solar. Afortunadamente, la mayoría de los paneles están bien diseñados para soportar condiciones climáticas extremas. Sin embargo, algunos paneles aún pueden fallar de varias maneras, incluida la entrada de agua, microfracturas celulares y degradación inducida potencial o PID. Es por eso que es vital que los paneles solares se fabriquen utilizando solo componentes de la más alta calidad. En nuestro otro artículo, los mejores paneles solares, destacamos a los principales fabricantes que utilizan materiales de la más alta calidad junto con pruebas según los más altos estándares de la industria.",
                    ReadbuildUpSolar: "El informe en sí está dividido en tres secciones, que detallan el riesgo para los activos solares que plantean los modelos financieros, el rendimiento operativo y el clima extremo. Cada sección presenta información de una variedad de colaboradores, incluidos PV Evolution Labs, BloombergNEF, Fracsun y Nextracker.",
                    //ReadMore End

                    singleply: "Solar de una sola capa",
                    panel: "Configuración del panel",
                    builtupsolar: "solar construido",
                    ModifiedSolarDetail: "Redefiniendo la energía solar para sus necesidades únicas",
                    HighSecurityDetail: "Alta Seguridad Solar: Protegiendo tu Inversión Solar con Soluciones Robustas",
                    SinglePlySolarDetail: "Redefiniendo Eficiencia y Sostenibilidad en Techos Solares",
                    PanelSetupDetail: "Configuración de Paneles Solares: Maximizando la Generación de Energía Solar para tu Espacio",
                    BuiltUpSolarDetail: "Energía Solar Integrada: Aprovechando el Poder del Sol con Integración Perfecta",
                    TeamsupportDetail: "Apoyo al Equipo para Energía Solar: Potenciando el Éxito de tu Equipo Solar",

                    // powerwall Start
                    GetPersonalized: "Obtén hoy el Diseño Personalizado de tu Sistema de Paneles Solares para el Hogar!",
                    GetPersonalizedDescription: "En MGP Energy, nos dedicamos a empoderar a los propietarios de viviendas con energía limpia y renovable. Con nuestro servicio de diseño personalizado de sistemas de paneles solares para el hogar, adaptamos las soluciones solares a tus necesidades energéticas únicas y a tu presupuesto. La energía solar te ayuda a ahorrar en las facturas de electricidad y reduce tu huella de carbono. Nuestro experimentado equipo garantiza un proceso de instalación sin complicaciones, maximizando la eficiencia del sistema. Da el primer paso hacia un futuro más ecológico. Contacta a [Nombre de tu Empresa de Paneles Solares] hoy mismo para comenzar tu camino hacia la energía solar.",
                    // powerwall End1

                    ProjectDone: "Proyecto terminado",
                    ActiveClient: "Cliente activa",
                    DrinkCoffee: "Beber café",
                    AwardWinning: 'Ganador de premios',

                },
            },
        }
    })

export default i18n;
