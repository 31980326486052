import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const Breadcrum = (props) => {
    const { t } = useTranslation();
    return (
        <>
            <section className={`breadcrumb-section breadcrumb-sky `} style={{backgroundImage: `url(${props?.bg})`}}>
                <div className="container" >
                    <div className="breadcrumb-text" data-aos="fade-down">
                        <h1>{props.title}</h1>
                        <p>{props.description}</p>
                    </div>
                    <ul className="breadcrumb-nav">
                        <li className='text-white'><Link className='text-white' to={props.linkUrl}>{t('home')}</Link></li>
                        <li className='text-white'>{props.pageName}</li>
                    </ul>
                    <span className="btg-text">Mgp energy</span>
                </div>
                <div className="breadcrumb-shape">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                        <path d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z">
                        </path>
                    </svg>
                </div>
            </section>
        </>
    )
}

export default Breadcrum