import React from 'react';
import { useTranslation } from 'react-i18next';


function InteriorEnergyScreen({description}) {
    const { t } = useTranslation();
    return (
        <>
            <div className="container py-5">
                <div className="row py-4">
                    <div dangerouslySetInnerHTML={{__html: description}} />
                </div>
            </div >
        </>
    )
}

export default InteriorEnergyScreen
