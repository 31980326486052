import React, { useEffect } from 'react'

const ScrollToTop = () => {


    const scrollTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }

    useEffect(() => {
        scrollTop()
    }, [])
    return (
        <></>
    )
}

export default ScrollToTop