import React, { lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "../Pages/Layout";
import InteriorEnergy from "../Pages/InteriorEnergy";
import { useGetPagesQuery } from "../Redux/Services";
import PrivacyPolicy from "../Pages/PrivacyPolicy";

const Home = lazy(() => import("../Pages/Home"));
const Services = lazy(() => import("../Pages/Services"));
const ContactUs = lazy(() => import("../Pages/ContactUs"));
const AboutUs = lazy(() => import("../Pages/AboutUs"));
const Faq = lazy(() => import("../Pages/Faq"));
const Partners = lazy(() => import("../Pages/Partners"));
// const InteriorEnergy = lazy(() => import("../Pages/InteriorEnergy"));
const Powerwall = lazy(() => import("../Pages/Powerwall"));
const TeamSupport = lazy(() => import("../Pages/TeamSupport"));
const ModifiedSolar = lazy(() => import("../Pages/ModifiedSolar"));
const HighSecurity = lazy(() => import("../Pages/HighSecurity"));
const SinglePlySolar = lazy(() => import("../Pages/SinglePlySolar"));
const PanelSetup = lazy(() => import("../Pages/PanelSetup"));
const BuiltUpSolar = lazy(() => import("../Pages/BuiltUpSolar"));
const OurTeam = lazy(() => import("../Pages/OurTeam"));


const AppRouting = () => {
    const { data } = useGetPagesQuery();

    const pages = data?.pages;
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    {pages?.map((page) => {
                        if (page.status === "1") {
                            return (
                                <Route
                                    key={page.id}
                                    path={page?.slug}
                                    element={
                                        (page.id === "1" && <Home />) ||
                                        (page.id === "2" && <AboutUs />) ||
                                        (page.id === "3" && <Services />) ||
                                        (page.id === "4" && <OurTeam />) ||
                                        (page.id === "5" && <Powerwall />) ||
                                        (page.id === "6" && <ContactUs />) ||
                                        (page.id === "7" && <Partners />) ||
                                        (page.id === "8" && <Faq />) ||
                                        // (page.id === "interior-energy" && <InteriorEnergy/>) ||
                                        (page.id === "PanelSetup" && <PanelSetup />) ||
                                        (page.id === "BuiltUpSolar" && <BuiltUpSolar />) ||
                                        (page.id === "TeamSupport" && <TeamSupport />) ||
                                        (page.id === "ModifiedSolar" && <ModifiedSolar />) ||
                                        (page.id === "HighSecurity" && <HighSecurity />) ||
                                        (page.id === "SinglePlySolar" ? <SinglePlySolar /> : "")
                                    }
                                />
                            );
                        } else {
                            return "";
                        }
                    })}
                    <Route path="service">
                        <Route path=":id" element={<InteriorEnergy />} />
                    </Route>
                    <Route path="privacy-policy" element={<PrivacyPolicy />} />
                    {/*<Route path="PanelSetup" element={<PanelSetup/>}/>*/}
                    {/*<Route path="BuiltUpSolar" element={<BuiltUpSolar/>}/>*/}
                    {/*<Route path="powerwall" element={<Powerwall/>}/>*/}
                    {/*<Route path="TeamSupport" element={<TeamSupport/>}/>*/}
                    {/*<Route path="ModifiedSolar" element={<ModifiedSolar/>}/>*/}
                    {/*<Route path="HighSecurity" element={<HighSecurity/>}/>*/}
                    {/*<Route path="SinglePlySolar" element={<SinglePlySolar/>}/>*/}

                    {/*<Route path="testing" element={<Testing />}/>*/}
                    {/*<Route path='OurTeam' element={<OurTeam />} />*/}
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default AppRouting;
