import React from 'react'
import { Link } from 'react-router-dom'
// import footer3 from "../../Assets/Images/03_2.jpg";
// import footer2 from "../../Assets/Images/02_2.jpg";
// import footer1 from "../../Assets/Images/01_2.jpg";
import { useTranslation } from 'react-i18next';
import { saveId } from "../../Redux/Reducers/userSlice";
import { useDispatch } from "react-redux";

const Footer = ({ company_pages, quick_pages, pageSettings }) => {
    const dispatch = useDispatch();

    const { t, i18n } = useTranslation();
    return (
        <>
            <footer>
                <div className="footer-top">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-sm-4">
                                {pageSettings?.logo && (
                                    <div className="logo text-center text-sm-left">
                                        <Link to="/">
                                            <img src={`${process.env.REACT_APP_BASE_URL}${pageSettings?.logo}`}
                                                width={150} alt="mgp-enerdy-logo" />
                                        </Link>
                                    </div>
                                )}
                            </div>
                            <div className="col-sm-8">
                                <div className="social-icon text-center text-sm-right">
                                    {pageSettings?.fb_link && (
                                        <a href={pageSettings?.fb_link} rel="noreferrer" target="_blank"><i
                                            className="fab fa-facebook-f" /></a>
                                    )}
                                    {pageSettings?.linkedin_link && (
                                        <a href={pageSettings?.linkedin_link} rel="noreferrer" target='_blank'><i
                                            className="fab fa-linkedin" /></a>
                                    )}
                                    {pageSettings?.insta_link && (
                                        <a href={pageSettings?.insta_link} rel="noreferrer" target='_blank'><i
                                            className="fab fa-instagram" /></a>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* widgets */}
                <div className="footer-widget-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2 col-md-6 col-sm-6">
                                <div className="widget nav-widget d-flex justify-content-start">
                                    <div>
                                        <h5 className="widget-title">{t("company")}</h5>
                                        {company_pages?.length > 0 && (
                                            <ul>
                                                {company_pages?.map((page) => {
                                                    if (page.status === '1') {
                                                        return (
                                                            <li onClick={() => dispatch(saveId(page.id))}>
                                                                <Link to={page.slug}>
                                                                    {i18n.language === 'en' ? page?.name : page?.name_sp}
                                                                </Link>
                                                            </li>
                                                        )
                                                    } else {
                                                        return "";
                                                    }
                                                })}
                                            </ul>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div
                                    className="widget nav-widget d-flex justify-content-start justify-content-lg-center">
                                    <div>
                                        <h5 className="widget-title">{t("quickLinks")}</h5>
                                        {quick_pages?.length > 0 && (
                                            <ul>
                                                {quick_pages?.map((page) => {
                                                    if (page.status === '1') {
                                                        return (
                                                            <li onClick={() => dispatch(saveId(page.id))}>
                                                                <Link to={page.slug}>
                                                                    {i18n.language === 'en' ? page?.name : page?.name_sp}
                                                                </Link>
                                                            </li>
                                                        )
                                                    } else {
                                                        return ""
                                                    }
                                                })}
                                                <li>

                                                <Link to={"/privacy-policy"}>
                                                    Privacy Policy
                                                </Link>
                                                </li>
                                            </ul>
                                        )}
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div
                                    className="widget nav-widget d-flex justify-content-start justify-content-lg-center">
                                    <div>
                                        <h5 className="widget-title">{t("address")}</h5>
                                        <ul>
                                            {pageSettings?.address && (
                                                <li>
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <div className="icon">
                                                                <i className="pe-7s-home" />
                                                            </div>
                                                        </div>
                                                        <div className="col ps-0">
                                                            <div className="content">
                                                                <p>MGP ENERGY SOLUTIONS, 51 C. Ulises Martínez, Humacao,
                                                                    00791, Puerto Rico</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )}
                                            {(pageSettings?.phone1 || pageSettings?.phone2) && (
                                                <li>
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <div className="icon">
                                                                <i className="pe-7s-call" />
                                                            </div>
                                                        </div>
                                                        <div className="col p-0">
                                                            <div className="content">
                                                                <p><a href={`tel:${pageSettings?.phone1}`}>+1
                                                                    787-254-8387</a></p>
                                                                <p><a href={`tel:${pageSettings?.phone2}`}>+1
                                                                    787-655-4464</a></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )}
                                            {pageSettings?.email && (
                                                <li>
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <div className="icon">
                                                                <i className="pe-7s-comment" />
                                                            </div>
                                                        </div>
                                                        <div className="col p-0">
                                                            <div className="content">
                                                                <p><a
                                                                    href={`mailto:${pageSettings?.email}`}>{pageSettings?.email}</a>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            {pageSettings?.map_frame && (
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="widget recent-post">
                                        <div className="">
                                            <iframe
                                                src={pageSettings?.map_frame}
                                                title='/'
                                                width="100%"
                                                height="250"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {/* copyright */}
                <div className="copy-right-area d-none">
                    <div className="container">
                        <div className="copyrigt-text d-sm-flex justify-content-between">
                            <p>Copyright By@<a href="http://www.technottix.com/" rel='noreferrer'
                                target="_blank">technottix</a></p>
                            <p>Design By@<a href="http://www.technottix.com/" rel='noreferrer'
                                target="_blank">technottix</a></p>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    )
}

export default Footer