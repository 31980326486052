import {createSlice} from "@reduxjs/toolkit";


const initialState = {
    id: null,
    user: null,
}


export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        saveId: (state, action) => {
            console.log('payload', action.payload)
            state.id = action.payload;
        }
    }
})

export const { saveId } = userSlice.actions;

export default userSlice.reducer;