import React from 'react';
import Logo from '../Assets/Images/logo.png';

const Loader = () => {
    return (
        <>
            <div className="vh-100 row align-items-center justify-content-center g-0">
                <div className="col-auto text-center">
                    <img src={Logo} alt="mgp.logo" height={60} className="normal-logo" />
                </div>
            </div>
        </>
    );
};

export default Loader;