import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Assets/App.scss';
import "./Assets/css/animate.min.css"
import "./Assets/css/bootstrap.min.css"
import "./Assets/css/font-awesome.min.css"
import "./Assets/css/pe-icon-7-stroke.css"
import "./Assets/css/magnific-popup.css"
import "./Assets/css/slick.css"
import "./Assets/css/meanmenu.min.css"
import "./Assets/css/default.css"
import "./Assets/css/style.css"
import "./Assets/css/custom-animation.scss"
import "./i18n.js";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {persistor, store} from "./Redux/Store";
import Loader from "./Component/Loader";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Provider store={store}>
          <PersistGate persistor={persistor}>
              <Suspense fallback={<Loader />}>
                  <App />
              </Suspense>
          </PersistGate>
      </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
