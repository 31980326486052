import { setupListeners } from '@reduxjs/toolkit/query'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

import { mgpApi } from "./Services";
import userReducer from "./Reducers/userSlice";
import {combineReducers, configureStore} from "@reduxjs/toolkit";

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['mgpApi'],
};

const rootReducer = combineReducers({
    [mgpApi.reducerPath]: mgpApi.reducer,
    users: userReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }).concat(mgpApi.middleware),
});

export const persistor = persistStore(store);
setupListeners(store.dispatch);
