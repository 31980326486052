import React from 'react'
import ScrollToTop from '../Component/Global/ScrollToTop'
import Breadcrum from '../Component/Global/Breadcrum';
import { useTranslation } from 'react-i18next';
import InteriorEnergyScreen from '../Component/ReadMore/InteriorEnergyScreen';
import {useSelector} from "react-redux";
import {useGetServiceDetailQuery} from "../Redux/Services";
import Loader from "../Component/Loader";
import {useParams} from "react-router-dom";

function InteriorEnergy() {
    const {id} = useParams();
    console.log('ids'. id)
    const {data, isLoading, error} = useGetServiceDetailQuery(id ? id : 1);

    const {t, i18n} = useTranslation();
    if (isLoading) return <Loader />;
    if (data?.status === 'error') return <div>Not found</div>;
    if (!data?.service) return <div>Not found</div>;

    const service = data?.service;
    return (
        <>
            <ScrollToTop />
            <Breadcrum
                bg={process.env.REACT_APP_BASE_URL + service?.banner_image}
                title={i18n.language === 'en' ? service?.card_heading : service?.card_heading_sp}
                description={i18n?.language === 'en' ? service?.card_description : service?.card_description_sp}
                linkUrl='/'
                pageName={i18n.language === 'en' ? service?.card_heading : service?.card_heading_sp}
            />
            <InteriorEnergyScreen description={i18n.language === 'en' ? service?.content_description : service?.content_description_sp} />
        </>
    )
}

export default InteriorEnergy
