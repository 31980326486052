import { useEffect } from 'react';
import './App.css';
import AppRouting from './Routing/AppRouting';

import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 600, // Duration of the animation (in milliseconds)
      easing: 'ease-in-out', // Easing function for the animation
      delay: 200,
    }); // Initialize AOS
  }, []);

  return (
    <>
      <AppRouting />
    </>
  );
}
const base = process.env.REACT_APP_API_URL
export default App;
