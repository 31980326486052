import React from 'react'
import Breadcrum from '../Component/Global/Breadcrum'
import image from "../Assets/Images/breadcrumb.jpg";
import PolicyContent from '../Component/PrivacyPolicy/PolicyContent';
import ScrollToTop from '../Component/Global/ScrollToTop';

const PrivacyPolicy = () => {
    return (
        <>
        <ScrollToTop/>
            <Breadcrum
                bg={image}
                title={"Privacy Policy"}
                description={"Read Our Privacy Policy"}
                linkUrl="/"
                pageName={"Privacy Policy"}
            />

            <PolicyContent />
        </>
    )
}

export default PrivacyPolicy