import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useTranslation } from "react-i18next";
import MessengerCustomerChat from "react-messenger-customer-chat";
import { useDispatch } from "react-redux";
import { saveId } from "../../Redux/Reducers/userSlice";

const Header = ({ pages, pageSettings }) => {
  const dispatch = useDispatch();

  const { i18n } = useTranslation();
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  const handleLanguage = (event) => {
    if (event.target.checked) {
      i18n.changeLanguage("sp");
      document.documentElement.setAttribute("lang", "de");
    } else {
      i18n.changeLanguage("en");
      document.documentElement.setAttribute("lang", "eng");
    }
  };

  useEffect(() => {
    i18n.changeLanguage("en");
  }, [i18n]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 2) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  console.log("language", i18n.resolvedLanguage);

  return (
    <>
      {/* <div id="messenger-chat-plugin">
        Messenger Customer Chat plugin
        <div
          className="fb-customerchat"
          attribution="setup_tool"
          page_id="731689646943862"
        ></div>
      </div> */}

      {/* <div id="custom-messenger-chat-container bg-danger"></div> */}

      <MessengerCustomerChat
        pageId="731689646943862"
        appId="278709988353777"
        // htmlRef="custom-messenger-chat-container"
      />

      {/* Mobile Navbar Start */}
      <section className="d-xl-none">
        <Navbar collapseOnSelect className="mob-header" expand="xl" bg="dark">
          <Container fluid>
            {pageSettings?.logo && (
              <Nav.Link as={Link} to="/" className="navbar-brand col">
                <img
                  src={process.env.REACT_APP_BASE_URL + pageSettings?.logo}
                  width={100}
                  alt="react.mgp"
                  className="normal-logo"
                />
              </Nav.Link>
            )}

            <div className="col-auto pe-0">
              <div className="switch">
                <input
                  id="languageToggle"
                  className="check-toggle check-toggle-round-flat"
                  type="checkbox"
                  onClick={handleLanguage}
                />
                <label htmlFor="languageToggle" className=" mb-0"></label>
                <span className="on">EN</span>
                <span className="off">SP</span>
              </div>
            </div>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                {pages?.slice(0, 6)?.map((page) => {
                  if (page.status === "1") {
                    return (
                      <Nav.Link
                        onClick={() => dispatch(saveId(page.id))}
                        className={`${
                          location.pathname === page.slug
                            ? "active"
                            : "text-light"
                        }`}
                        as={Link}
                        to={page.slug}
                        eventKey={page.id}
                        key={page.id}
                      >
                        {i18n.language === "en" ? page?.name : page?.name_sp}
                      </Nav.Link>
                    );
                  } else {
                    return "";
                  }
                })}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </section>
      {/* Mobile Navbar End */}

      {/* <header className="bg-dark  header-three sticky-header d-xl-block d-none"> */}
      {/* <header class="header-absolute header-three sticky-header  d-xl-block d-none"> */}
      <header
        className={`${
          !isScrolled
            ? "header-three header-absolute sticky-header"
            : "header-absolute header-three sticky-header sticky bg-dark"
        }     d-xl-block d-none`}
      >
        <div className="container-fluid custom-container-two">
          <div className="mainmenu-area" style={{ height: 75 }}>
            <div className="d-flex align-items-center justify-content-between h-100">
              <nav className="main-menu">
                <div className="logo">
                  {pageSettings?.logo && (
                    <Link to={"/"}>
                      <img
                        src={
                          process.env.REACT_APP_BASE_URL + pageSettings?.logo
                        }
                        alt="mgp.react.logo"
                        height={60}
                        className="normal-logo"
                      />
                      <img
                        src={
                          process.env.REACT_APP_BASE_URL + pageSettings?.logo
                        }
                        alt="mgp.react.sticky.logo"
                        height={50}
                        className="sticky-logo"
                      />
                    </Link>
                  )}
                </div>
                <div className="menu-items">
                  <ul>
                    {pages?.slice(0, 6)?.map((page) => {
                      if (page.status === "1") {
                        return (
                          <li
                            onClick={() => dispatch(saveId(page.id))}
                            className={`${
                              location.pathname === page.slug ? "active" : ""
                            }`}
                          >
                            <Link to={page.slug}>
                              {i18n.language === "en"
                                ? page?.name
                                : page?.name_sp}
                            </Link>
                          </li>
                        );
                      } else {
                        return "";
                      }
                    })}
                    {/*<li className={`${location.pathname === "/" ? "active" : ''}`}>
                      <Link to={"/"}>{t("home")}</Link>

                       <ul className="submenu">
                        <li><a href="index.html">Home One</a></li>
                        <li><a href="index-2.html">Home Two</a></li>
                        <li><a href="index-3.html">Home Three</a></li>
                      </ul>
                    </li>
                    <li className={`${location.pathname === "/about-us" ? "active" : ''}`}>
                      <Link to={"/about-us"}>{t("aboutUs")}</Link>
                    </li>
                    <li className={`${location.pathname === "/services" ? "active" : ''}`}>
                      <Link to={"/services"}>{t("services")}</Link>

                       <ul className="submenu">
                        <li><a href="service-details.html">Service Details</a></li>
                      </ul>
                    </li>

                    <li className={`${location.pathname === "/OurTeam" ? "active" : ''}`}>
                      <Link to={"/OurTeam"}>{t("OurTeam")}</Link>

                       <ul className="submenu">
                        <li><a href="service-details.html">Service Details</a></li>
                      </ul>
                    </li>

                    <li className={`${location.pathname === "/powerwall" ? "active" : ''}`}>
                      <Link to={"/powerwall"}>{t("powerwall")}</Link>

                       <ul className="submenu">
                        <li><a href="service-details.html">Service Details</a></li>
                      </ul>
                    </li>

                    <li className={`${location.pathname === "/contact-us" ? "active" : ''}`}>
                      <Link to={"/contact-us"}>{t("contactUs")}</Link>

                       <ul className="submenu">
                        <li><a href="service-details.html">Service Details</a></li>
                      </ul>
                    </li>*/}
                    {/* <li>
                      <a href="/">Pages</a>
                      <ul className="submenu">
                        <li>
                          <a href="why-choose-us.html">Why Choose Us</a>
                        </li>
                        <li>
                          <a href="team.html">Team</a>
                          <ul className="submenu">
                            <li><a href="team-details.html">Team Details</a></li>
                          </ul>
                        </li>
                        <li>
                          <a href="portfolio-grid.html">Portfolio</a>
                          <ul className="submenu">
                            <li><a href="portfolio-grid.html">Portfolio Grid</a></li>
                            <li><a href="portfolio-masonary.html">Portfolio Masonary</a></li>
                            <li><a href="portfolio-slider.html">Portfolio Slider</a></li>
                            <li><a href="portfolio-details.html">Portfolio Details</a></li>
                          </ul>
                        </li>
                        <li>
                          <a href="blog-standard.html">News</a>
                          <ul className="submenu">
                            <li><a href="blog-standard.html">News Standard</a> </li>
                            <li><a href="blog-grid.html">News Grid</a> </li>
                            <li><a href="blog-details.html">News Details</a></li>
                          </ul>
                        </li>
                        <li>
                          <a href="faq.html">FAQ</a>
                        </li>
                      </ul>
                    </li> */}
                    {/* <li>
                      <a href="blog-standard.html">News</a>
                      <ul className="submenu">
                        <li><a href="blog-standard.html">News Standard</a> </li>
                        <li><a href="blog-grid.html">News Grid</a> </li>
                        <li><a href="blog-details.html">News Details</a></li>
                      </ul>
                    </li> */}
                  </ul>
                </div>
              </nav>
              <div className="main-right">
                {/* <Link to={"/contact-us"} className="main-btn btn-filled">
                  Get Started
                </Link> */}
                <div className="col-auto">
                  <div className="switch">
                    <input
                      id="language-toggle"
                      className="check-toggle check-toggle-round-flat"
                      type="checkbox"
                      onClick={handleLanguage}
                    />
                    <label htmlFor="language-toggle" className="mb-0"></label>
                    <span className="on">EN</span>
                    <span className="off">SP</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Mobile Menu */}
          <div className="row">
            <div className="col-12">
              <div className="mobile-menu" />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
