import React from 'react'
import Header from "../Component/Main/Header";
import Footer from "../Component/Main/Footer";
import { Outlet } from 'react-router-dom';
import {useGetPageSettingsQuery, useGetPagesQuery} from "../Redux/Services";
import Loader from "../Component/Loader";
import ScrollToTop from "../Component/Global/ScrollToTop";

const Layout = () => {
    const {data: page, isLoading, error: pageError} = useGetPagesQuery()
    const {data: setting, isLoading:  isLoadingSetting, error: settingError } = useGetPageSettingsQuery();

    if (isLoading || isLoadingSetting) return <Loader />;
    if (page?.status === 'error' || setting?.status === 'error') return <div>Not found</div>;
    // if (pageError || settingError) return <div>Error</div>;

    return (
        <>
            <ScrollToTop />
            <Header
                pages={page?.pages}
                pageSettings={setting?.settings}
            />
            <Outlet />
            <Footer
                pageSettings={setting?.settings}
                company_pages={page?.company_pages}
                quick_pages={page?.quick_pages}
            />
        </>
    )
}

export default Layout